<template>
  <div>
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.4/css/all.css" crossorigin="anonymous"/>


    <div class="stats" v-if="stats[0]">
          <div class="stats-text">
            <h1>{{ stats[0] }} Services monitored<span v-if="!onlyShowIssues">.</span><span v-if="onlyShowIssues">, <h2 style="color: yellow">only showing <b>issues</b>.<br/><br/></h2></span></h1>
            <h2 v-if="stats[1] && !onlyShowIssues"><span style="color: red">{{ stats[1] }} Service<span v-if="stats[1] > 1">s</span><span v-else> is </span> having issues.</span></h2>
            <h2 v-if="!stats[1]" class="allup">yey, all Services are up &#128522;</h2>
            <h3 v-if="!showCustomerSpecific" v-on:click="showAllCats = !showAllCats"><span v-if="showAllCats">Dont </span>Show all Services</h3>
          </div>
          <div class="stats-notification" v-if="pwa">
            <div v-on:click="EnableNotifications()">
              <i v-if="!pushEnabled" class="far fa-bell"></i>
              <i v-if="pushEnabled" class="fas fa-bell"></i>
            </div>
          </div>
    </div>


            <div class="group" v-on:click="show[g.name] = !show[g.name]" v-for="g in metrics.filter(e => e.is_public == true)" v-bind:key="g.name">
              <div class="group-header">
                <div class="group-status-icon">
                    <span class="dot" v-if="g.allok"/>
                    <span class="dot red" v-if="!g.allok"/>
                </div>
                <div class="group-title-text">Datacenter Services</div>
                <div class="group-last-check" v-if="parseInt(g.state.lastCheckC) < 900">Last Check: {{ parseInt(g.state.lastCheckC) }} secs. ago</div>
              </div>
              <Transition>

              <div v-if="show[g.name]|showAllCats">
                <div class="group-services" v-for="s in g.checks" v-bind:key="s.name">
                  <div class="group-status">
                      <span class="dot" v-if="s.isOK"/>
                      <span class="dot red" v-if="!s.isOK"/>
                  </div>
                    <div class="group-service">{{ s.name }}</div>
                </div>
              </div>
              </Transition>
            </div>



      <div class="group" v-on:click="show[g.name]=!show[g.name]" v-for="g in metrics.filter(e => e.is_public == false)" v-bind:key="g.name">
        <div class="group-header" v-if="(onlyShowIssues && !g.allok) ||(onlyShowIssues == false)">
          <div class="group-status-icon">
              <span class="dot" v-if="g.allok"/>
              <span class="dot red" v-if="!g.allok"/>
          </div>
          <div class="group-title-text">{{ g.name }}</div>
          <div class="group-last-check" v-if="parseInt(g.state.lastCheckC) < 900">Last Check: {{ parseInt(g.state.lastCheckC) }} secs. ago</div>
        </div>
        <Transition>
        <div v-if="show[g.name] ||(!g.allok && !isAdmin) || showAllCats|| showCustomerSpecific">
          <div class="group-services"  v-for="s in g.checks" v-bind:key="s.name">
            <div style="display: flex" v-if="(onlyShowIssues && !s.isOK) ||!onlyShowIssues">
              <div class="group-status">
                <div class="group-status-icon">
                    <span class="dot" v-if="s.isOK"/>
                    <span class="dot red" v-if="!s.isOK"/>                  
                </div>
              </div>
              <div class="group-service">{{ s.name }}
                <div class="group-service-status" v-if="!s.isOK && !onlyShowIssues">{{ s.description }}</div>
              </div>
            </div>
          </div>
        </div>
        </Transition>
      </div>


  </div>
</template><script>
import axios from 'axios';

export default({
  data() {
    return {
      pwa: false,
      pushEnabled: false,
      client_id: false,
      pushKeys: false,
      show: [],
      metrics: [],
      isAdmin: false,
      onlyShowIssues: false,
      showAllCats: false,
      showCustomerSpecific: false,
    }
  },
  computed: {
    pub_serv() {
      let ov = [];
      this.metrics.forEach((m) => {
        if(m.is_public) ov.push(m.checks);
      })
      return {
        name: 'publuic',
        checks: ov,
      };
    },
    stats() {
      let checks = 0;
      let fails = 0;

      this.metrics.filter(e => e.is_public == false).forEach((m) => {
        checks = checks + m.checks.length;
      })
      this.metrics.filter(e => e.is_public == false).forEach((m) => {
        fails = fails + m.checks.filter(e => !e.isOK).length;
      })

      return [ checks, fails];
    }
  },
  methods: {
    setNotificationEnabled() {
      this.pushEnabled = true;
      localStorage.setItem("notifications", true);
    },
    EnableNotifications() {
      if(this.pushEnabled) return false;
      Notification.requestPermission(function (result) {
        if (result !== "granted") return false;
        navigator.serviceWorker
        .register("/sw.js")
        .then(async (registration) => {
          registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: "BLw4ihzWCdHbCHcMB9dqp977t3X71rg574rM2A9lEIBvx4R5gEWzxPNainPhZn7-IHLw6NT11_RflC9eYFx_nrA",
          })
          .then((keys) => {
            fetch("/api/register-push-client", {
              method: 'POST',
              headers: {
                Authorization: this.client_id,
              },
              body: JSON.stringify(keys),
            })
            .then(async (r) => {
                    const data = await r.json();
                    alert(JSON.stringify(data));
                    this.setNotificationEnabled();
            })
          });
        })
      }.bind(this));
    },
    fetchData() {

      const search = new URLSearchParams(location.search);
      const id = search.has("q") ? search.get("q") : prompt("id?");
      this.pwa = search.has("pwa");
      this.isAdmin = search.has("a");
      this.onlyShowIssues = search.has("i");
      if(search.has("o")) this.showCustomerSpecific = true;
      this.client_id = id;






      var myDynamicManifest = {
        "name": "Monitoring",
        "short_name": "Monitoring",
        "display": "standalone",
        "start_url": "https://mon.srv.rest/?pwa=true&q="+id,
        "background_color": "#161616",
        "description": "A readable Hacker News app.",
        "icons": [
          {
            "src": "/favicon.ico",
            "type": "image/x-icon",
            "sizes": "16x16 32x32"
          },
          {
            "src": "/icon-192.png",
            "type": "image/png",
            "sizes": "192x192"
          },
          {
            "src": "/icon-512.png",
            "type": "image/png",
            "sizes": "512x512"
          },
          {
            "src": "/icon-192-maskable.png",
            "type": "image/png",
            "sizes": "192x192",
            "purpose": "maskable"
          },
          {
            "src": "/icon-512-maskable.png",
            "type": "image/png",
            "sizes": "512x512",
            "purpose": "maskable"
          }
        ]
      }

      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

      var link = document.createElement('Link');
      link.rel = "manifest";
      link.setAttribute('href', 'data:application/json;charset=8' + stringManifest)






      axios.get("/api/"+id+"/metrics")
        .then((r) => {
          this.metrics = r.data;
          setTimeout(() => {
            this.fetchData();
          }, 15000)
        })      
    }
  },
  created() {


    this.pushEnabled = localStorage.getItem("notifications");
    const registerServiceWorker = async () => {
      if ("serviceWorker" in navigator) {
        try {
          const registration = await navigator.serviceWorker.register("/sw.js", {
            scope: "/",
          });
          if (registration.installing) {
            console.log("Service worker installing");
          } else if (registration.waiting) {
            console.log("Service worker installed");
          } else if (registration.active) {
            console.log("Service worker active");
          }
        } catch (error) {
          console.error(`Registration failed with ${error}`);
        }
      } else {
        alert("Service Worker not supported.")
      }
    };

    registerServiceWorker();
    this.fetchData();
  },
})
</script>
<style scoped>
.stats {
  margin-bottom: 45px;
  display: flex;
  max-width: 800px;
  margin: auto;
}

.stats-text {
  width: calc( 100% - 50px);
}
.stats-notification {
  width: 50px;
  display: flex;
}
.stats-notification i {
  cursor: pointer;
  color: #fff;
  font-size: 2rem;
}
h2 {
  font-size: 12pt;
}
h3 {
  font-size: 9pt;
  cursor: pointer;
  font-weight: normal;
}
.group {
  max-width: 800px;
  margin: auto;
  margin-bottom: 30px;
}
h1, h2 {
  margin: 0px;
  padding: 0px;
  color: #fff;
}
.group-header {
  max-width: 800px;
  color: #fff;
  background-color: #323232;
  margin: auto;
  text-align: left;
  display: flex;
  line-height: 45px;
  cursor: pointer;
}
.group-status-icon, .group-status {
  width: 50px;
  align-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.group-services:nth-child(even) {
  background-color: rgba(50, 50, 50, 0.5);
}



.group-title-text {
  margin-left: 15px;
  width: calc(100% - 50px - 150px);
}
.group-services {
  width: 100%;
  line-height: 45px;
  text-align: left;
  display: flex;
  color: #fff;
  overflow: hidden !important;
}


.group-service {
  margin-left: 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.group-service-status{
  color: #ff4800;
  line-height: 25px;
  margin-bottom: 15px;
  font-weight: bold;
} 

.red {
  color: red !important;
  background: red !important;
}

.dot {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    color: #51de3e;
    background: #51de3e;
    position: relative;
    -ms-transform: none;
    transform: none;
}

.dot:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: currentColor;
    animation: pulse 2s infinite;
    opacity: 1;
    border-radius: 50%;
    top: 0;
    left: 0
}

@media (prefers-reduced-motion) {
    .dot:after {
        animation: none !important
    }
}

.dot.is-big {
    width: 60px;
    height: 60px
}

.dot.is-big:after {
    animation: pulseBig 2s infinite
}

@media (prefers-reduced-motion) {
    .dot.is-big:after {
        animation: none !important
    }
}

.compact .dot.is-big {
    width: 30px;
    height: 30px
}

.dot.is-error {
    color: #964542;
    background: #964542
}

.dot.is-warning {
    color: #964542;
    background: #964542
}

.dot.is-grey {
    color: #964542;
    background: #964542
}

.dot.is-grey:after {
    display: none
}

@keyframes pulseBig {
    0% {
        opacity: 0.5
    }

    70% {
        opacity: 0;
        transform: scale(1.6)
    }

    100% {
        opacity: 0
    }
}

@keyframes pulse {
    0% {
        opacity: 0.5
    }

    70% {
        opacity: 0;
        transform: scale(2.5)
    }

    100% {
        opacity: 0
    }
}

.allup {
    color: #3bd671;
}
.group-last-check {
  color: silver;
  font-size: 0.8rem;
  float: right;
  width: 150px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


@media only screen and (max-width: 600px) {
  .group-last-check {
    display: none !important;
  }
}
</style>